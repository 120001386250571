<template>
  <div>
    <table class="table table-google">
      <tbody>
        <tr>
          <td class="row-fit text-nowrap">Burn Duration</td>
          <td>
            <div v-if="burnDuration === 0">Not available</div>
            <div v-else>{{ formatDuration(burnDuration) }}</div>
          </td>
        </tr>

        <tr>
          <td class="row-fit  text-nowrap">Starting Weight</td>
          <td>
            <div class="flex gap-1">
              <div>
                {{ cycle.weight_start_ts | longdate }} {{ cycle.weight_start_ts | time }}
              </div>
              <div>-</div>
              <div class="font-bold">{{ cycle.weight_start_value | round }} g</div>
            </div>
          </td>
        </tr>

         <tr>
            <td class="row-fit">Ending Weight</td>
            <td>
              <div v-if="cycle.weight_end_id" class="flex gap-1">
                <div>
                  {{ cycle.weight_end_ts | longdate }} {{ cycle.weight_end_ts | time }}
                </div>
                <div>-</div>
                <div class="font-bold">{{ cycle.weight_end_value | round }} g</div>
              </div>
              <div v-else-if="cycle.weight_start_id">
                In Progress
              </div>
            </td>
          </tr>
      </tbody>
    </table>

    <div class="hiddenz mb-3">
      <b-btn
        size="sm"
        variant="primary"
        :disabled="isLoading"
        @click="generateGraphData"
      >
        Generate graphs
      </b-btn>
    </div>

    <div v-if="cycle.graph_data">
      <graph-data :data="cycle.graph_data" :startTs="cycle.weight_start_ts" />
    </div>
    <div v-else-if="graphData">
      <graph-data :data="graphData" :startTs="cycle.weight_start_ts" />
    </div>
    <div v-else class="flex gap-2 items-center">
      <i class="fas fa-exclamation-triangle text-warning"></i>
      <div>Graph data not available</div>
    </div>
  </div>
</template>

<script>
import { formatDuration } from '@/helpers';

const GraphData = () => import('@/components/scale/GraphData.vue');

export default {
  name: 'CycleGraphVue',
  components: {
    GraphData,
  },
  props: {
    cycle: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    burnDuration() {
      const now = parseInt(new Date().getTime() / 1000, 10);
      const startTime = this.cycle.weight_start_ts ?? now;
      const endTime = this.cycle.weight_end_ts ?? now;
      const burnDuration = endTime - startTime;
      return burnDuration;
    },
  },
  data() {
    return {
      loadingCount: 0,
      graphData: null,
    };
  },
  methods: {
    formatDuration(seconds) {
      return formatDuration(seconds);
    },
    fetchGraphData() {
      this.loadingCount++;
      this.$http
        .get(`/soot_sample/cycles/${this.cycle.id}/graphData`)
        .then((res) => {
          this.graphData = res.body.graphData;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch graph data: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    generateGraphData() {
      this.loadingCount++;
      this.$http
        .post(`/soot_sample/cycles/${this.cycle.id}/finalizeCycle`)
        .then((res) => {
          this.graphData = null;
          this.cycle.graph_data = null;
          this.$nextTick().then(() => {
            this.cycle.graph_data = res.body.cycle.graph_data;
          });
          this.$emit('updated', res.body.sample);
        })
        .catch((err) => {
          this.$toast.error(`Failed to update cycle graphs: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    if (this.cycle.graph_data === null) {
      this.fetchGraphData();
    }
  },
};
</script>

<style lang="scss" scoped>
  .table-google tbody td {
    padding-left: 0;
  }
</style>
